import { PersonContact } from "../typings/contacts.typings";
import { ChildrenRenderProps } from "@sgbs-ui/core";
import * as React from "react";
import { BulletValue } from "./BulletValue/BulletValue";
import { ContactQualityView } from "@ic-anywhere/ic-dal";
import { ContactIndicatorsTooltip } from "@ic-anywhere/ic-components";
import { toDisplayedDate } from "@ic-anywhere/ic-utils";

export function renderIndividualContact(
  onClickOnMandates?: (item: PersonContact) => void,
  showMandateCounter = true,
  showDateOfBirth = true,
  contactQuality?: ContactQualityView
) {
  return (item: PersonContact, { withHighlight, isReadOnly }: ChildrenRenderProps): React.ReactElement => {
    const handleOnClickOnMandates = (e: React.MouseEvent): void => {
      e.preventDefault();
      e.stopPropagation();
      if (onClickOnMandates) {
        onClickOnMandates(item);
      }
    };

    const valueClassName = "text-small";

    const counter =
      item.activeMandateCountMatchingFilters > 0 ? item.activeMandateCountMatchingFilters : item.activeMandateCount;

    return (
      <span className="d-flex align-items-center">
        <em className={`icon icon-sm ${!isReadOnly ? "text-secondary" : ""} mr-2`}>
          {item.type === "person" ? "person_outline" : "person"}
        </em>
        {withHighlight(item.fullName ?? "")}
        {item.type === "contact" && item.mainEmail && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName} value={item.mainEmail} />
        )}
        {item.type === "contact" && item.employer && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName} value={item.employer} />
        )}
        {showDateOfBirth && item.dateOfBirth && item.type === "person" && (
          <BulletValue
            isReadOnly={isReadOnly}
            className={valueClassName}
            value={toDisplayedDate(item.dateOfBirth, "MMM yy")}
          />
        )}
        {item.type === "person" && item.mandatedRootAccount && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName} value={item.mandatedRootAccount} />
        )}
        {item.type === "person" && item.mandateRole && (
          <BulletValue isReadOnly={isReadOnly} className={valueClassName} value={item.mandateRole} />
        )}
        {showMandateCounter && item.activeMandateCount > 0 && (
          <BulletValue isReadOnly={isReadOnly}>
            <a href="" onClick={handleOnClickOnMandates} className={`text-nowrap ${valueClassName}`}>
              {counter} mandates
            </a>
          </BulletValue>
        )}
        {contactQuality && (
          <span style={{ maxHeight: "20px" }}>
            <ContactIndicatorsTooltip
              displayMode={"image"}
              contactQualityIndicators={contactQuality}
              className={"ml-2"}
            />
          </span>
        )}
      </span>
    );
  };
}
