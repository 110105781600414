import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import { PersonContact } from "../common/typings/contacts.typings";
import PersonContactPicker from "./PersonContactPicker";
import { SGBSSize } from "@sgbs-ui/core";
import { WithSGB4 } from "../common/components/WithSGB4";
import WidgetTracker from "../common/components/WidgetTracker";
import { CommonPickerProps, CreateLinkMode, CreateLinkPickerProps, EmitOnChangeMode } from "../common/typings";
import { EMIT_MODE, PLACEHOLDER, SIZE } from "../common/constants";
import { useCreateLinkClicked } from "../common/hooks/useCreateLinkClicked";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions, ThirdId } from "@ic-anywhere/ic-dal";

const TAG = "ic-person-contact-single-picker";

export interface Props extends CommonPickerProps<{ contact: PersonContact | null }, string>, CreateLinkPickerProps {
  selectedId: string;
  readonlyId: string;
  functionId?: string;
  roleId?: string;
  dropdownZIndex?: number;
  showMandateCounter?: string;
  ignoreClickOutside?: string;
  withQualityIndicators?: string;
  showQualityIndicators?: string;
  showDateOfBirth?: string;
  useHistory?: string;
  contactsIds?: string[];
  mandateThirdId?: ThirdId;
  onClickOnMandates?: (item: PersonContact) => void;
}

export const PersonContactPickerWidget: React.FC<Props> = ({
  id,
  selectedId,
  readonlyId,
  errorMessage,
  inError,
  maxResultCount,
  onCreateLinkClicked,
  onReady,
  onChange,
  onClickOnMandates,
  placeholder = PLACEHOLDER,
  createLinkMode = "emit",
  size = SIZE,
  disabled,
  outline,
  createActionText,
  emitMode = EMIT_MODE,
  dropdownZIndex = 2000,
  ignoreClickOutside,
  functionId,
  roleId,
  showMandateCounter = "true",
  withQualityIndicators,
  showQualityIndicators,
  showDateOfBirth,
  useHistory,
  contactsIds,
  mandateThirdId,
}) => {
  const handleOnChange = (contact: PersonContact | null): void => {
    onChange?.({ contact });
  };

  const handleOnCreateLinkClicked = useCreateLinkClicked(createLinkMode, "contacts", onCreateLinkClicked);
  const queryClient = new QueryClient({ defaultOptions: defaultQueryClientOptions });

  return (
    <WithSGB4>
      <QueryClientProvider client={queryClient}>
        <PersonContactPicker
          id={id}
          placeholder={placeholder}
          maxResultCount={maxResultCount}
          selectedId={selectedId}
          readonlyId={readonlyId}
          size={size}
          emitMode={emitMode}
          inError={convertToBoolOrUndefined(inError)}
          disabled={convertToBoolOrUndefined(disabled)}
          outline={convertToBoolOrUndefined(outline)}
          showMandateCounter={convertToBoolOrUndefined(showMandateCounter)}
          functionId={functionId}
          roleId={roleId}
          errorMessage={errorMessage}
          onChange={handleOnChange}
          onReady={onReady}
          createLinkMode={createLinkMode}
          onClickOnMandates={onClickOnMandates}
          onCreateLinkClicked={handleOnCreateLinkClicked}
          createActionText={createActionText}
          dropdownZIndex={dropdownZIndex}
          ignoreClickOutside={convertToBoolOrUndefined(ignoreClickOutside)}
          withQualityIndicators={convertToBoolOrUndefined(withQualityIndicators)}
          showQualityIndicators={convertToBoolOrUndefined(showQualityIndicators)}
          showDateOfBirth={convertToBoolOrUndefined(showDateOfBirth)}
          useHistory={convertToBoolOrUndefined(useHistory)}
          contactsIds={contactsIds}
          mandateThirdId={mandateThirdId}
        />
        <WidgetTracker widgetName={TAG} />
      </QueryClientProvider>
    </WithSGB4>
  );
};

widgetize(
  TAG,
  PersonContactPickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
    createActionText: WidgetPropsMapping.asString(),
    selectedId: WidgetPropsMapping.asString(),
    readonlyId: WidgetPropsMapping.asString(),
    dropdownZIndex: WidgetPropsMapping.asNumber(),
    ignoreClickOutside: WidgetPropsMapping.asString(),
    functionId: WidgetPropsMapping.asString(),
    roleId: WidgetPropsMapping.asString(),
    showMandateCounter: WidgetPropsMapping.asString(),
    withQualityIndicators: WidgetPropsMapping.asString(),
    showQualityIndicators: WidgetPropsMapping.asString(),
    showDateOfBirth: WidgetPropsMapping.asString(),
    useHistory: WidgetPropsMapping.asString(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
    onCreateLinkClicked: WidgetPropsMapping.asEventEmitter("create-clicked"),
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onClickOnMandates: WidgetPropsMapping.asEventEmitter("mandate-clicked"),
    contactsIds: WidgetPropsMapping.asObject(),
    mandateThirdId: WidgetPropsMapping.asObject(),
  },
  { neverInjectGlobalCss: true }
);
